@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}

header {
  position: fixed;
  top: 0;
  background-color: #003CAF;
  border-radius: 0 0 36px 36px;
  width: 100%;
  display: flex;
  flex-direction: row;

  .panel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-right: 80px;
  }

  button {
    line-height: 30px;
    font-weight: 400;
    font-size: 20px;
    color: white;
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 12px;
    margin: 20px;

    &.start {
      border: none;
      background: #0067FF;
    }

    &.login {
      border: 1px solid #0067FF;
      background-color: transparent;
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  img {
    margin-right: 100px;
    padding: 40px;
    cursor: pointer;
  }

  .navigation {
    display: flex;
    flex-direction: row;
    color: white;
    font-weight: 400;
    font-size: 20px;

    div {
      padding-left: 30px;
      padding-right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        color: white;
        text-decoration: none;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.heading {
  padding-top: 205px;
  padding-bottom: 100px;
  background-color: #F0F3F8;
  border-radius: 0 0 204px 204px;
  height: 500px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 300px;

  .info {
    max-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-size: 48px;
    line-height: 72px;
  }

  span {
    color: #0067FF;
  }

  .image-container {

  }

  button {
    font-size: 20px;
    line-height: 30px;
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 12px;
    margin: 20px;
  }

  .presentation {
    background-color: white;
    color: black;
    border: none;
  }

  .start {
    background-color: #0067FF;
    color: white;
    border: none;
  }
}

.info-section {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 70px;
  padding-bottom: 70px;
  display: flex;
  justify-content: center;

  .container {
    display: flex;
    flex-direction: row;
    max-width: 1400px;

    &.reverse {
      flex-direction: row-reverse;
    }

    .text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 50px;

      .title {
        font-weight: 700;
        font-size: 48px;
        margin-bottom: 20px;
      }

      .info {
        font-weight: 400;
        font-size: 24px;
      }
    }
  }
}

.features {
  .title {
    font-weight: 900;
    font-size: 48px;
    line-height: 59px;
    text-align: center;
    font-style: italic;
    margin-bottom: 20px;

    u {
      text-decoration: underline;
      text-decoration-color: #0067FF;
      text-decoration-thickness: 5px;
    }
  }

  .container {
    background-color: #EFF2F6;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .content {
      max-width: 1200px;
      width: 100%;

      .items {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 340px;
        width: 100%;

        .item {
          display: flex;
          flex-direction: column;

          .header {
            cursor: pointer;
            color: #0067FF;
            font-weight: 700;
            font-size: 16px;
            line-height: 76px;
            display: flex;
            align-items: center;

            img {
              margin-right: 10px;
            }
          }

          .description {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            padding: 25px 18px;
            background-color: white;
            box-shadow: 0px 4px 34px #D8DFE8;
            border-radius: 16px;
          }
        }
      }

      .image {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }
}

.bginfo {
  background-color: #005DFF;
  height: 707px;
  display: flex;
  justify-content: center;

  .container {
    max-width: 1400px;
    height: 100%;
    width: 100%;
    background-image: url('../../assets/img/background.png');
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-size: 40px;
    color: white;
    text-align: center;

    .buttons {
      margin-top: 30px;
      display: flex;
      flex-direction: row;
    }

    button {
      font-size: 20px;
      line-height: 30px;
      cursor: pointer;
      padding: 10px 20px;
      border-radius: 12px;
      margin: 20px;
    }

    .presentation {
      background-color: white;
      color: black;
      border: none;
    }

    .start {
      background-color: #00349A;
      color: white;
      border: none;
    }
  }
}

footer {
  padding: 50px;
  min-height: 100px;
  background-color: black;
  color: white;
  font-size: 16px;
  display: flex;
  justify-content: center;

  .container {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1400px;
    font-weight: 300;
    line-height: 24px;

    .header {
      font-weight: 600;
      font-size: 20px;
    }

    .column {
      width: 30%;

      &:last-child {
        width: 10%;
      }
    }

    .icons {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: end;
      padding-right: 30px;
    }
  }
}

.write-us {
  background: #F4F4F4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;

  .title {
    font-weight: 700;
    font-size: 48px;
    line-height: 103%;
    text-align: center;
    padding: 60px;
  }

  .container {
    background: #FFFFFF;
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.07);
    border-radius: 36px;
    max-width: 800px;
    padding: 90px;
    width: 100%;

    .row {
      &:first-child {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;

        input {
          font-size: 14px;
          color: #9FA1B1;
          width: 100%;
          border: 1px solid #EDEEF1;
          padding: 10px;
          border-radius: 4px;

          &:first-child {
            margin-right: 20px;
          }

          &:last-child {
            margin-right: 20px;
          }
        }
      }

      &:nth-child(2) {
        textarea {
          font-size: 14px;
          color: #9FA1B1;
          width: 100%;
          padding: 10px;
          border: 1px solid #EDEEF1;
          border-radius: 4px;
        }
      }

      &:last-child {
        padding-top: 30px;
        display: flex;
        flex-direction: row;
        justify-content: end;

        button {
          padding: 10px 20px;
          color: white;
          border: none;
          font-size: 20px;
          line-height: 30px;
          cursor: pointer;
          border-radius: 12px;
          background-color: #0067FF;
        }
      }
    }
  }
}

.plans {
  padding: 50px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .container {
    padding: 50px;
    margin-top: 30px;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 84px rgba(0, 0, 0, 0.15);
    border-radius: 60px;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    color: #0D429F;

    table.btntable {
      width: 100%;

      tr {
        td {
          width: 33%;
          text-align: center;
          padding-top: 20px;

          button {
            line-height: 30px;
            font-weight: 400;
            font-size: 20px;
            color: white;
            cursor: pointer;
            padding: 10px 20px;
            border-radius: 12px;
            background-color: #0067FF;
            border: none;
          }
        }
      }
    }

    table.plantable {
      padding-left: 5px;
      padding-top: 5px;
      padding-bottom: 5px;
      font-weight: 400;
      font-size: 18px;
      border-radius: 33px 0px 33px 33px;
      border: 0.5px solid #8AB9FF;

      .missing {
        color: #8297B0;
      }

      .soon {
        font-weight: bold;
      }

      thead {
        th:first-child {
          border: none;
        }

        th:not(:first-child) {
          border-radius: 16px 16px 0px 0px;
          border: 0.5px solid #8AB9FF;
          padding-bottom: 40px;
          padding-top: 40px;
          font-weight: 700;
          font-size: 24px;
          line-height: 36px;
        }

        th:last-child {
          color: white;
          background-color: #0054D2;
        }
      }

      tbody {
        tr:first-child {
          td:first-child {
            border-radius: 24px 0px 0px 0px;
          }
        }

        tr:last-child {
          td:first-child {
            border-radius: 0px 0px 0px 24px;
          }

          td:last-child {
            border-radius: 0px 0px 24px 0px;
          }
        }

        tr {
          td {
            height: 74px;
            width: 33%;
          }

          td:first-child {
            padding-left: 22px;
          }

          td:not(:first-child) {
            text-align: center;
          }
        }

        tr:nth-child(odd) {
          td {
            background: #E8F1FF;
          }
        }
      }
    }

    .row {

    }
  }

  .title {
    text-align: center;
    line-height: 103%;
    font-weight: 700;
    font-size: 48px;

    span {
      color: #0067FF;
    }
  }

  .info {
    width: 100%;
    margin-top: 60px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .text {
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;
      max-width: 800px;
    }

    button {
      margin-top: 30px;
      line-height: 30px;
      font-weight: 400;
      font-size: 20px;
      color: white;
      cursor: pointer;
      padding: 10px 20px;
      border-radius: 12px;
      background-color: #0067FF;
      border: none;
    }
  }
}

.d-flex-column {
  display: flex;
  flex-direction: column;
}

.d-flex-row {
  display: flex;
  flex-direction: row;
}

.faq {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .title {
    font-weight: 700;
    font-size: 48px;
    margin-bottom: 20px;
    text-align: center;

    & > span {
      color: #0067FF;
    }
  }

  .container {
    max-width: 900px;
    padding-bottom: 40px;

    .item {
      display: flex;
      flex-direction: row;

      .question {
        cursor: pointer;
        font-weight: 600;
        font-size: 20px;
        line-height: 134%;
        margin-bottom: 10px;
        margin-top: 10px;
      }

      .answer {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
      }

      .icon {
        cursor: pointer;
        transition: 0.6s ease;
        img {
          transform: rotate(180deg);
          transition: 0.6s ease;
        }
      }

      &.opened {
        .icon {
          img {
            transform: rotate(0deg);
            transition: 0.6s ease;
          }
        }
      }
    }
  }
}